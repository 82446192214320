<template>
	<tr>
		<td>
			<span>{{ name }}</span>
		</td>
		<td>
			<span>{{ message }}</span>
		</td>
		<td>
			<span>{{ timer }} min.</span>
		</td>
		<td>
			<v-dialog v-model="dialogEditInterval" max-width="500px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" color="success">
						<v-icon>mdi-pencil</v-icon>		
					</v-btn>
				</template>

				<v-card class="pa-3">
					<v-form ref="form" @submit.prevent="UpdateInterval()" lazy-validation>
						<v-card-title>
							<span class="text-h5">Edit {{ interval.name }}</span>
						</v-card-title>

						<v-card-text>
							<v-row>
								<v-col cols="12" sm="12" md="12" lg="6" xl="6">
									<v-text-field 
										v-model.trim="$v.name.$model"
										:error-messages="NameErrors"
										@input="$v.name.$touch()"
										@blur="$v.name.$touch()"
										label="Name"
										required
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="12" md="12" lg="6" xl="6">
									<v-text-field
										v-model.trim="$v.timer.$model"
										:error-messages="IntervalErrors"
										@input="$v.timer.$touch()"
										@blur="$v.timer.$touch()"
										type="number"
										label="Interval time in minutes"
										required
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-textarea 
										v-model.trim="$v.message.$model"
										:error-messages="MsgErrors"
										@input="$v.message.$touch()"
										@blur="$v.message.$touch()"
										label="Message"
										required
									></v-textarea>
								</v-col>
							</v-row>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="dialogEditInterval = false">Close</v-btn>
							<v-btn 
								text 
								color="blue darken-1" 
								type="submit"
								:loading="loadingEditInterval" 
								:disabled="loadingEditInterval"
							>Save</v-btn>
						</v-card-actions>
					</v-form>
				</v-card>
			</v-dialog>

			<v-dialog v-model="dialogDeleteInterval" max-width="450px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-bind="attrs" 
						v-on="on" 
						icon 
						color="error"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>

				<v-card>
					<v-card-title>
						<v-icon class="mr-2">mdi-alert</v-icon>
						{{ name }}
					</v-card-title>

					<v-card-text>
						Are you sure you want to delete this repeating message?
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="dialogDeleteInterval = false">Close</v-btn>
						<v-btn color="red darken-1" text :loading="loadingDelInterval" :disabled="loadingDelInterval" @click="DeleteInterval(id)">Delete</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</td>
	</tr>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators';

export default {
	props: ['interval'],

	data() {
		return {
			dialogEditInterval: false,
			dialogDeleteInterval: false,
			loadingEditInterval: false,
			loadingDelInterval: false,
			id: this.interval.id,
			name: this.interval.name,
			message: this.interval.message,
			timer: this.interval.timer
		}
	},

	validations() {
		return {
			name: {
				required
			},
			message: {
				required
			},
			timer: {
				required,
				minValue: minValue(1)
			}
		};
	},

	computed: {
		NameErrors() {
			const errors = [];
			if (!this.$v.name.$dirty) return errors;
			!this.$v.name.required && errors.push('Name is required.');
			return errors;
		},

		MsgErrors() {
			const errors = [];
			if (!this.$v.message.$dirty) return errors;
			!this.$v.message.required && errors.push('Message is required.');
			return errors;
		},

		IntervalErrors() {
			const errors = [];
			if (!this.$v.timer.$dirty) return errors;
			!this.$v.timer.required && errors.push('Interval is required.');
			!this.$v.timer.minValue && errors.push('Minimum time is 1min.');
			return errors;
		},
	},

	methods: {
		async UpdateInterval () {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				try {
					this.loadingEditInterval = true;

					const request = await fetch(`${this.$store.state.base_url}/intervals/update`, {
						method: 'PUT',
						body: JSON.stringify({
							id: this.id,
							name: this.name,
							message: this.message,
							timer: this.timer
						}),
						headers: {
							"Content-type" : "application/json; charset=UTF-8",
							"Authorization" : this.$cookies.get('guardian_key_session'),
						}
					});

					const res = await request.json();

					if (request.ok) {
						if (res.isValueUpdated) {
							this.$notify({
								group: 'main',
								type: 'success',
								title: this.$store.state.app_name,
								text: 'Interval Message updated successfully!'
							});

							this.$emit('IntervalEdited');
							this.dialogEditInterval = false;
						}
						else if (res.isValueNotChanged) {
							this.$notify({
								group: 'main',
								type: 'warn',
								title: this.$store.state.app_name,
								text: 'Interval Message is already saved! No changes made.'
							});

							this.dialogEditInterval = false;
						}
						else if (res.error) {
							this.$notify({
								group: 'main',
								type: 'error',
								title: this.$store.state.app_name,
								text: res.error
							});
						}
					}
					
					if (Array.isArray(res)) {
						res.forEach(err => {
							this.$notify({
								group: 'main',
								type: 'error',
								title: this.$store.state.app_name,
								text: err.error
							});
						});
					}

					if (request.status == 500 || request.status == 401) {
						if (res.error && this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
							return;
						}

						throw (res);
					}
				}
				catch (error) {
					this.$notify({
						group: 'main',
						type: 'error',
						title: this.$store.state.app_name,
						text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
					});
				}
				finally {
					this.loadingEditInterval = false;
				}
			}
		},

		async DeleteInterval(interval_id) {
			try {
				this.loadingDelInterval = true;

				const request = await fetch(`${this.$store.state.base_url}/intervals/delete`, {
					method: 'DELETE',
					body: JSON.stringify({
						id: interval_id
					}),
					headers: {
						"Content-type" : "application/json; charset=UTF-8",
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});

				const res = await request.json();

				if (request.ok) {
					if (res.isIntervalDeleted) {
						this.$emit('IntervalDeleted');
						this.dialogDeleteInterval = false;

						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: 'Interval Message deleted!'
						});
					}
					else if (res.error) {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: res.error
						});
					}
				}
				
				if (request.status == 500 || request.status == 401) {
					if (res.error && this.$store.state.error_types.includes(res.error)) {
						this.sessionExpiredHandler(res);
						return;
					}

					throw (res);
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
				});
			}
			finally {
				this.loadingDelInterval = false;
			}
		}
	},
}
</script>