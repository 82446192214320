<template>
	<v-dialog v-model="dialogNewRepeatingMessage" @click:outside="$v.$reset()" max-width="550px">
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" color="pink" fab small class="mx-3">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</template>

		<v-card>
			<v-form class="pa-2" @submit.prevent="SaveInterval">
				<v-card-title>
					<span class="text-center">New Interval Message</span>
				</v-card-title>

				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="6">
								<v-text-field 
									clearable
									v-model.trim="$v.name.$model"
									:error-messages="NameErrors"
									@input="$v.name.$touch()"
									@blur="$v.name.$touch()"
									label="Name*" 
									required
								></v-text-field>
							</v-col>

							<v-col cols="6">
								<v-text-field 
									clearable
									v-model.trim="$v.interval.$model"
									:error-messages="IntervalErrors"
									@input="$v.interval.$touch()"
									@blur="$v.interval.$touch()"
									type="number"
									label="Interval*" 
									hint="Timer is in minutes"
									required
								></v-text-field>
							</v-col>

							<v-col cols="12">
								<v-textarea 
									clearable
									v-model.trim="$v.message.$model"
									:error-messages="MsgErrors"
									@input="$v.message.$touch()"
									@blur="$v.message.$touch()"
									label="Message*" 
									required
								></v-textarea>
							</v-col>
						</v-row>
					</v-container>
					<small>* indicates required field</small>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeIntervalDialog">Close</v-btn>
					<v-btn color="blue darken-1" text :loading="loadingBtn" :disabled="loadingBtn" type="submit">Save</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators';

export default {
	data() {
		return {
			dialogNewRepeatingMessage: false,
			loadingBtn: false,
			name: '',
			message: '',
			interval: null
		}
	},

	validations() {
		return {
			name: {
				required
			},
			message: {
				required
			},
			interval: {
				required,
				minValue: minValue(1)
			}
		};
	},

	computed: {
		NameErrors() {
			const errors = [];
			if (!this.$v.name.$dirty) return errors;
			!this.$v.name.required && errors.push('Name is required.');
			return errors;
		},

		MsgErrors() {
			const errors = [];
			if (!this.$v.message.$dirty) return errors;
			!this.$v.message.required && errors.push('Message is required.');
			return errors;
		},

		IntervalErrors() {
			const errors = [];
			if (!this.$v.interval.$dirty) return errors;
			!this.$v.interval.required && errors.push('Interval is required.');
			!this.$v.interval.minValue && errors.push('Minimum time is 1min.');
			return errors;
		},
	},

	methods: {
		closeIntervalDialog() {
			this.dialogNewRepeatingMessage = false;
			this.$v.$reset();
		},

		async SaveInterval() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.loadingBtn = true;

				try {
					const request = await fetch(`${this.$store.state.base_url}/intervals/new`, {
						method: 'POST',
						body: JSON.stringify({
							name: this.name,
							message: this.message,
							interval: this.interval
						}),
						headers: {
							"Content-type" : "application/json; charset=UTF-8",
							"Authorization" : this.$cookies.get('guardian_key_session'),
						}
					});

					const res = await request.json();
					
					if (request.ok) {	
						if (res.isIntervalAdded) {
							this.$v.$reset();
							
							this.name = '';
							this.message = '';
							this.interval = null;

							this.loadingBtn = false;

							this.$notify({
								group: 'main',
								type: 'success',
								title: this.$store.state.app_name,
								text: 'Interval added successfully!'
							});

							this.$emit('NewIntervalAdded');
							this.dialogNewRepeatingMessage = false;
						}
						else {
							this.$notify({
								group: 'main',
								type: 'error',
								title: this.$store.state.app_name,
								text: res.error
							});
						}
					}
					else {
						if (Array.isArray(res)) {
							res.forEach(err => {
								this.$notify({
									group: 'main',
									type: 'error',
									title: this.$store.state.app_name,
									text: err.error
								});
							});
						}
						
						if (res.error) {
							if (this.$store.state.error_types.includes(res.error)) {
								this.sessionExpiredHandler(res);
							}
							else {
								throw res.error;
							}
						}
					}

					if (request.status == 500 || request.status == 401) {
						if (res.error && this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
							return;
						}

						throw (res);
					}
				}
				catch (error) {
					this.$notify({
						group: 'main',
						type: 'error',
						title: this.$store.state.app_name,
						text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
					});
				}
				finally {
					this.loadingBtn = false;
				}
			}
		},
	},
}
</script>